<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Ingressi"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getIngressi"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
          <v-layout>
            <v-flex>
              <v-btn class="ml-0" flat @click="toggleRicerca()">
                Ricerca avanzata
                <v-icon v-if="!showRicerca">arrow_right</v-icon>
                <v-icon v-if="showRicerca">arrow_drop_down</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="showRicerca">
            <v-flex>
              <v-layout>
                <v-flex xs3>
                  <v-select
                    class="ma-2 ml-4"
                    label="Cliente"
                    :items="clienti"
                    item-text="ragione_sociale"
                    item-value="cliente_id"
                    v-model="ricerca.cliente_id" />
                </v-flex>
                <v-flex xs3>
                  <v-autocomplete
                    class="ma-2"
                    label="Destinazione"
                    :items="destinazioni"
                    item-text="presso"
                    item-value="_id"
                    v-model="ricerca.destinazione_id"
                    :loading="destLoading"
                    :search-input.sync="searchDest"
                    hide-no-data
                    hide-selected
                    clearable
                    />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    class="ma-2"
                    label="Identificativo"
                    v-model="ricerca.identificativo" />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    class="ma-2"
                    label="OT"
                    v-model="ricerca.ot" />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs3 class="pa-2">
                  <v-btn
                    class="ml-0"
                    color="primary"
                    @click.native="changePage(1)">
                    Filtra
                  </v-btn>
                  <v-btn
                    @click="reset_cerca()">
                    Ripristina
                  </v-btn>
                </v-flex>
                <v-flex xs3>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!ingressi.length">
          Nessun ingresso trovato
        </v-alert>
        <v-list class="mt-2" three-line v-if="ingressi.length">
          <template v-for="(ingresso, index) in ingressi">
            <v-list-tile
              @click="$router.push({ name: 'ingresso', params: { id: ingresso._id} })"
              avatar
              :key="'tile-'+ingresso._id">
              <v-list-tile-avatar>
                <v-avatar>
                  <v-tooltip top>
                    <semaphore slot="activator" :stato="ingresso.inviato ? 0 : 2" />
                    {{ !ingresso.inviato ? 'Non ancora inviato alla gestione' : 'Già inviato alla gestione'}}
                  </v-tooltip>
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize">
                  {{ingresso.identificativo}}
                  <span v-if="ingresso.sede===1">(Cormano)</span>
                  <span v-else>(Paderno)</span>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ ingresso.presso }} - {{ ingresso.indirizzo }}, {{ ingresso.cap }} {{ ingresso.citta }} ({{ ingresso.provincia }})
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  {{ingresso.descrizione}}
                  <v-tooltip right v-if="ingresso.more_data.length">
                    <span slot="activator" class="secondary--text"> +{{ingresso.more_data.length}}</span>
                    <span v-for="(item, index) in ingresso.more_data" :key="'more-'+index">
                      {{item.descrizione}}<br />
                    </span>
                  </v-tooltip>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text class="text--primary">{{ ingresso.data }}</v-list-tile-action-text>
                <v-list-tile-action-text class="text--primary">{{ ingresso.ot }}</v-list-tile-action-text>
                <v-list-tile-action-text class="text--primary">{{ ingresso.ragione_sociale }}</v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="ingresso._id" v-if="index < ingressi.length - 1"></v-divider>
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click="$router.push({ name: 'ingresso', params: { id: 'add' } })">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo Ingresso</span>
      </v-tooltip>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import Semaphore from '@/components/common/Semaphore'
import _debounce from 'lodash/debounce'
export default {
  data () {
    return {
      ingressi: [],
      ingresso: {},
      search: null,
      pages: 0,
      showRicerca: false,
      ricerca: {},
      clienti: [],
      destinazioni: [],
      destLoading: false,
      searchDest: null,
      page: 1,
      paginate: 20
    }
  },
  components: {
    PageTitle,
    Semaphore
  },
  methods: {
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    },
    getIngressi () {
      let o = {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      }
      if (this.showRicerca && !this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      let processData = function (ing) {
        ing.descrizione = null
        ing.more_data = []
        ing.lavorazioni.forEach(function (item) {
          let desc = ''
          if (item.opera) desc += item.opera
          if (item.codice_opera) desc += ' - ' + item.codice_opera
          if (item.descrizione && item.descrizione !== '-') desc += ' - ' + item.descrizione
          desc += ' - Componenti: ' + item.componenti.length
          if (ing.descrizione === null) {
            ing.descrizione = desc
          } else {
            ing.more_data.push({ descrizione: desc })
          }
        })
      }

      this.$plsqlReadAll('ingresso', o)
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.ingressi = response.data.items
            this.pages = response.data.pages
            this.ingressi.forEach(function (ing) { processData(ing) })
          }
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
        this.changePage(1)
      }
    },
    toggleRicerca () {
      if (!this.showRicerca && this.clienti.length === 0) {
        this.getClienti()
      }
      this.showRicerca = !this.showRicerca
    },
    getClienti () {
      this.$plsqlMethod('ddtp', 'get_clienti', { })
        .then(response => {
          this.clienti = response.data
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Clienti' })
        })
    },
    cercaDest (val) {
      if (!val) return

      this.destLoading = true

      this.$plsqlMethod('destinazione', 'search', { text: val })
        .then(response => {
          this.destinazioni = response.data
          this.destLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Destinazioni' })
          this.destLoading = false
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getIngressi()
    }
  },
  watch: {
    searchDest (val) {
      this.debouncedcercaDest(val)
    }
  },
  created: function () {
    this.debouncedcercaDest = _debounce(this.cercaDest, 500)
  },
  mounted () {
    this.getIngressi()
  }
}
</script>
